import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import "./Homepage.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import { BarChart } from "./BarChart";
import { ENDPOINTS } from "./endpoint";
import Timer from "./Timer";

function Homepage() {
  const [reportlink, setReportlink] = useState();
  const [showSearchResult, setShowSearchResult] = useState([]);
  const [flag, setFlag] = useState(false);
  const [respTime, setRespTime] = useState(0);
  const [mainResultResp, setMainResultResp] = useState([]);
  const [mainResult, setMainResult] = useState([]);
  const [id, setId] = useState();
  const [chatId, setChatId] = useState(1);
  const [suggestions, setSuggestions] = useState([]);
  const [autoSuggest, setAutoSuggest] = useState([]);
  const [addChat, setAddChat] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [recentInputValue, setRecentInputValue] = useState("");
  const [toggleResp, setToggleResp] = useState(0);
  const [circular, setCircular] = useState("hidden");
  const [recentUserIndex, setRecentUserIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [confirmationVisible, setConfirmationVisible] = useState(true);
  const [toggleChart, setToggleChart] = useState(false);
  const [panel, setPanel] = useState({
    like: false,
    unlike: false,
    download: false,
    chart: false,
    // panel5: false,
    // result: false,
  });
  const [feedbackStatus, setFeedbackStatus] = useState({});
  const [webapiUrl, setWebapiUrl] = useState("");
  const [username, setUsername] = useState("");
  const { usertype, customerID, UserName } = useParams();
  const chatContainerRef = useRef(null);
  const regex = /^[a-zA-Z0-9\s?'.,]*[a-zA-Z\s?'.,][a-zA-Z0-9\s?'.,]*$/;
  const regexReplace = /\('([^']+)',\)/g;
  const checks = [null, "", "'", ",", ".", "?"];

  const Search = async (word, index) => {
    try {
      setFlag(false);
      setShowSearchResult([]);
      const isAlpha = regex.test(word);
      if (checks.includes(word) || word.trim() === "") {
        return;
      }
      setCircular("visible");
      const startTime = new Date(); // Store the start time before sending the request
      const response = await axios.post(`${ENDPOINTS}/nlp_to_sql`, {
        question: word,
        ApiUrl: webapiUrl
          ? `http://${webapiUrl}`
          : "http://sphinx-api.shrmpro.com/api/AIReport/AIReport",
        UserName: username ? username : "nitish.k@sphinxworldbiz.com",
      });
      console.log(response, "responseresponseresponseresponseresponse");
      setReportlink(response.data.report_link);
      const responseTime = new Date();
      setCircular("hidden");
      const endTime = new Date(); // Store the end time after receiving the response
      const duration = (endTime - startTime) / 1000; // Calculate the duration in milliseconds
      setRespTime(duration);
      if (response.data.error) {
        console.log(response.data.error, "response.data.error");
        const res = await axios.post(`${ENDPOINTS}/similarquestion`, {
          question: word,
        });
        console.log(res);
        setMainResult((prevResults) => [
          ...prevResults,
          {
            content: "Suggestions",
            suggest: res.data.Data,
            time: responseTime.toLocaleTimeString(),
            type: "response",
          },
        ]);
        return;
      }

      setId(response.data.idfeedback);
      const jsonString = response.data.Data.replace(/'/g, '"')
        .replace(/Decimal\(([^)]+)\)/g, "$1")
        .replace(/\n/g, " ")
        .replace(/\r/g, " ")
        .replace(/\t/g, "")
        .replace(/None/g, null)
        .replace("\t", "")
        .replace("\\", "/")
        .replace("\n", " ")
        .replace("\r\n\r\n", " ")
        .replace("\r\n", " ")
        .replace("\r", " ");

      const jsonArray = JSON.parse(jsonString);
      setShowSearchResult(jsonArray);

      setMainResult((prevResults) => [
        ...prevResults,
        {
          content: jsonArray,
          link: response.data.report_link,
          id: response.data.idfeedback,
          time: responseTime.toLocaleTimeString(),
          type: "response",
        },
      ]);

      setFlag(true);
    } catch (error) {
      console.error("An error occurred during the search:", error);
    }
  };

  const headers = Object.keys(showSearchResult[0] ? showSearchResult[0] : "");

  const greetWelcome = [
    "hii",
    "hi",
    "Hii",
    "Hi",
    "HI",
    "hello",
    "Hello",
    "HELLO",
  ];
  const greetBye = ["Bye", "BYE", "bye"];

  const queryFinder = async (question) => {
    if (question === "") {
      return;
    }
    try {
      const startTime = new Date(); // Store the start time before sending the request
      const questionTime = new Date();
      setCircular("visible");
      setFlag(false);
      if (checks.includes(question) || question.trim() === "") {
        return;
      }
      setMainResult((prevResults) => {
        const updatedResults = [
          ...prevResults,
          {
            content: question,
            time: questionTime.toLocaleTimeString(),
            type: "user",
          },
        ];
        setRecentUserIndex(updatedResults.length - 1);
        return updatedResults;
      });

      setInputValue("");
      if (
        !greetWelcome.includes(question.toLowerCase()) &&
        !greetBye.includes(question.toLowerCase())
      ) {
        const resp = await axios.post(`${ENDPOINTS}/autoreturn`, {
          question: question,
          ApiUrl: webapiUrl
            ? `http://${webapiUrl}`
            : "http://hrms219.sphinxworldbiz.com:4090/api/AIReport/AIReport",
          UserName: username ? username : "Administrator",
        });
        if (resp.data.error) {
          const responseTime = new Date();
          console.log(resp.data.error, "resp.data.error");
          setMainResult((prevResults) => [
            ...prevResults,
            {
              content: resp.data.error,
              // suggest: res.data.Data,
              time: responseTime.toLocaleTimeString(),
              type: "response",
            },
          ]);
          setCircular("hidden");
          return;
        }

        const jsonString = resp.data.Data.replace(/'/g, '"')
          .replace(/Decimal\(([^)]+)\)/g, "$1")
          .replace(/\n/g, " ")
          .replace(/\r/g, " ")
          .replace(/\t/g, "")
          .replace(/None/g, null)
          .replace("\t", "")
          .replace("\\", "/")
          .replace("\n", " ")
          .replace("\r\n\r\n", " ")
          .replace("\r\n", " ")
          .replace("\r", " ");

        try {
          const jsonArray = JSON.parse(jsonString);
          const endTime = new Date(); // Store the end time after receiving the response
          const duration = (endTime - startTime) / 1000; // Calculate the duration in milliseconds
          setRespTime(duration);
          const responseTime = new Date();
          setId(resp.data.idfeedback);

          if (Array.isArray(jsonArray)) {
            setShowSearchResult(jsonArray);
            setMainResult((prevResults) => [
              ...prevResults,
              {
                content: jsonArray,
                id: resp.data.idfeedback,
                time: responseTime.toLocaleTimeString(),
                type: "response",
              },
            ]);
          } else {
            setMainResult((prevResults) => {
              const updatedResults = [
                ...prevResults,
                {
                  content: jsonArray,
                  id: resp.data.idfeedback,
                  time: responseTime.toLocaleTimeString(),
                  type: "response",
                },
              ];
              setRecentUserIndex(updatedResults.length - 1);
              return updatedResults;
            });
          }
          setFlag(true);
        } catch (error) {
          console.error(jsonString, "Error parsing JSON:", error);

          const responseTime = new Date();
          setId(resp.data.idfeedback);
          setMainResult((prevResults) => {
            const updatedResults = [
              ...prevResults,
              {
                content: jsonString,
                id: resp.data.idfeedback,
                time: responseTime.toLocaleTimeString(),
                type: "response",
              },
            ];
            setRecentUserIndex(updatedResults.length - 1);
            return updatedResults;
          });
        }
      } else {
        setMainResult((prevResults) => {
          const updatedResults = [
            ...prevResults,
            {
              content: greetBye.includes(question.toLowerCase())
                ? "Bye"
                : "Hii",
              time: questionTime.toLocaleTimeString(),
              type: "response",
            },
          ];
          setRecentUserIndex(updatedResults.length - 1);
          return updatedResults;
        });
      }

      setCircular("hidden");
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleInputChange = (e) => {
    try {
      setToggleChart(false);

      if (regex.test(e.target.value)) {
        setRecentInputValue(e.target.value);
        setInputValue(e.target.value);
        const value = e.target.value.toLowerCase();
        if (value) {
          const matchingChoices = autoSuggest.filter((choice) =>
            choice.toLowerCase().includes(value)
          );

          setSuggestions(matchingChoices);
        } else {
          setSuggestions([]);
        }
      } else {
        setRecentInputValue(e.target.value);
        setInputValue(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const suggestionSearch = async (word, index) => {
    try {
      setFlag(false);
      setShowSearchResult([]);
      const isAlpha = regex.test(word);

      if (checks.includes(word) || word.trim() === "") {
        return;
      }

      setCircular("visible");
      const startTime = new Date(); // Store the start time before sending the request
      const response = await axios.post(`${ENDPOINTS}/nlp_to_sql`, {
        question: word,
        ApiUrl: webapiUrl
          ? `http://${webapiUrl}`
          : "http://hrms219.sphinxworldbiz.com:4090/api/AIReport/AIReport",
        UserName: username ? username : "Administrator",
      });
      const responseTime = new Date();
      setCircular("hidden");
      const endTime = new Date(); // Store the end time after receiving the response
      const duration = (endTime - startTime) / 1000; // Calculate the duration in milliseconds
      setRespTime(duration);
      console.log(response, "response");
      if (response.data.error) {
        console.log(response.data.error, "response.data.error");
        // const res = await axios.post(`${ENDPOINTS}/similarquestion`,{
        //   question: word,
        // })
        // console.log(res)
        setMainResult((prevResults) => [
          ...prevResults,
          {
            content: response.data.error,
            // suggest: res.data.Data,
            time: responseTime.toLocaleTimeString(),
            type: "response",
          },
        ]);
        return;
      }

      setId(response.data.idfeedback);

      const jsonString = response.data.Data.replace(/'/g, '"')
        .replace(/Decimal\(([^)]+)\)/g, "$1")
        .replace(/\n/g, " ")
        .replace(/\r/g, " ")
        .replace(/\t/g, "")
        .replace(/None/g, null)
        .replace("\t", "")
        .replace("\\", "/")
        .replace("\n", " ")
        .replace("\r\n\r\n", " ")
        .replace("\r\n", " ")
        .replace("\r", " ");

      const jsonArray = JSON.parse(jsonString);
      setShowSearchResult(jsonArray);

      setMainResult((prevResults) => [
        ...prevResults,
        {
          content: jsonArray,
          id: response.data.idfeedback,
          time: responseTime.toLocaleTimeString(),
          type: "response",
        },
      ]);

      setFlag(true);
    } catch (error) {
      console.error("An error occurred during the search:", error);
    }
  };

  const AutoSuggest = async (text) => {
    try {
      if (checks.includes(text) || text.trim() === "") {
        return;
      }

      const isAlpha = regex.test(text);
      if (isAlpha) {
        const response = await axios.post(`${ENDPOINTS}/autosuggest`, {
          question: text,
          usertype: usertype || 1,
          customerID: customerID || 1,
        });

        if (response.data.error) {
          console.error("API Error:", response.data.error);
          return;
        }

        setAutoSuggest(response.data.Data);
      }
    } catch (error) {
      console.error("An error occurred during API request:", error);
    }
  };

  const handleSuggestionClick = (word) => {
    const responseTime = new Date();
    setMainResult((prevResults) => {
      const updatedResults = [
        ...prevResults,
        {
          content: word,
          time: responseTime.toLocaleTimeString(),
          type: "user",
        },
      ];
      setRecentUserIndex(updatedResults.length - 1);
      return updatedResults;
    });
    Search(word);
    setInputValue("");
    // queryFinder(word)
    setSuggestions([]);
  };

  const feedBack = async (type, itemId) => {
    try {
      setFeedbackStatus((prevStatus) => ({
        ...prevStatus,
        [itemId]: prevStatus[itemId] === type ? null : type,
      }));

      const response = await axios.put(`${ENDPOINTS}/update_feedback`, {
        idfeedback: itemId,
        iscorrectans: type === "like" ? true : false,
        userfeedback: type === "like" ? "Good" : "Bad",
      });

      if (response.status === 200) {
        // Optionally, you can handle success logic here if needed.
      }
    } catch (error) {
      console.error("An error occurred during feedback update:", error);
    }
  };

  useEffect(() => {}, [feedbackStatus]);

  const notInterested = () => {
    const responseTime = new Date();
    setMainResult((prevResults) => [
      ...prevResults,
      {
        content: "How may I help You?",
        // suggest: res.data.Data,
        time: responseTime.toLocaleTimeString(),
        type: "response",
      },
    ]);
  };
  const generateCsvData = () => {
    let csvContent = "";
    csvContent = headers + "\n";
    showSearchResult.forEach((item) => {
      const row = Object.values(item).join(",") + "\n";
      csvContent += row;
    });

    return csvContent;
  };

  const handleDownload = () => {
    const csvData = generateCsvData();
    if (showSearchResult.length > 0) {
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "Download.csv");
    }
  };

  const handleConfirmBox = (item, i) => {
    console.log(item, "item(");
    // setMainResult((prevResults) => [...prevResults, { content: "Try Another Query", time: currentTime.toLocaleTimeString(), type: 'response' }]);
    Search(recentInputValue);
  };

  const addChats = async () => {
    try {
      const json = JSON.stringify(mainResult);
      const correctJson = json.replace(/"/g, "'");

      const history = await axios.post(`${ENDPOINTS}/chats`, {
        Text: correctJson,
        username: username ? username : "Administrator",
        Client_id: 1,
        chatID: chatId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const newChat = async () => {
    try {
      const resp = await axios.post(`${ENDPOINTS}/getchatid`, {
        username: username ? username : "Administrator",
      });
      setChatId(resp.data.Data);

      if (mainResult.length > 0) {
        setAddChat((prevResults) => {
          const existingChatIndex = prevResults.findIndex(
            (chat) => chat.id === toggleResp
          );
          if (existingChatIndex !== -1) {
            // If the chat already exists, update its content
            const updatedChats = [...prevResults];
            updatedChats[existingChatIndex].content = mainResult;
            return updatedChats;
          } else {
            // If the chat doesn't exist, add a new chat
            const addChats = {
              content: mainResult,
              type: mainResult[0].content,
              id: Date.now(),
            };
            return [...prevResults, addChats];
          }
        });
      }
      setMainResult([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getChats = async () => {
    try {
      const response = await axios.post(`${ENDPOINTS}/getchats`, {
        username: username ? username : "Administrator",
        Client_id: 1,
      });

      if (response.data.Data) {
        const chatObjects = response.data.Data;
        const parsedArray = chatObjects.map((chatObj) => {
          if (chatObj.chat) {
            try {
              const jsonParse = chatObj.chat
                .replace(/'/g, '"')
                .replace(/Decimal\(([^)]+)\)/g, "$1")
                .replace(/\n/g, " ")
                .replace(/\r/g, " ")
                .replace(/\t/g, "")
                .replace(/None/g, null)
                .replace("\t", "")
                .replace("\\", "/")
                .replace("\n", " ")
                .replace("\r\n\r\n", " ")
                .replace("\r\n", " ")
                .replace("\r", " ");

              const parsedChat = JSON.parse(jsonParse);
              return {
                id: chatObj.id, // Assuming there is an 'id' property in each chatObj
                chat: parsedChat,
              };
            } catch (error) {
              console.error(
                `Error parsing chat string: ${chatObj.chat}`,
                error
              );
              return null;
            }
          } else {
            console.error("Chat object is missing 'chat' property:", chatObj);
            return null;
          }
        });

        setAddChat(parsedArray.filter(Boolean)); // Remove null values
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  const updateChat = (content, id) => {
    setMainResult(content);
    setToggleResp(id);
    setChatId(id);
  };

  // useEffect(() => {
  //   // Update the current time every second
  //   const intervalId = setInterval(() => {
  //     setCurrentTime(new Date());
  //     setCurrentDate(new Date());
  //   }, 1000);

  //   // Cleanup the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);

  const dateOptions = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);

  useEffect(() => {
    // Get the current URL
    const url = new URL(window.location.href);
    // Extract webapiUrl parameter from the URL
    const webapiUrlParam = url.searchParams.get("webapiUrl");
    // Extract username parameter from the URL
    const usernameParam = url.searchParams.get("username");

    setWebapiUrl(webapiUrlParam);
    setUsername(usernameParam);
  }, []);
  console.log(mainResult, "mainnnn");
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    // setCurrentTime(new Date());
    setCurrentDate(new Date());

    // const intervalId = setInterval(() => {
    //   setCurrentTime(new Date());
    //   setCurrentDate(new Date());
    // }, 1000);

    // // Cleanup the interval on component unmount
    // return () => clearInterval(intervalId);
  }, [mainResult, toggleChart]);

  useEffect(() => {
    if (mainResult.length > 0) {
      addChats();
    }

    // if(mainResult){
    getChats();
    // }
  }, [mainResult]);

  useEffect(() => {
    axios
      .post(`${ENDPOINTS}/getchatid`, {
        username: username ? username : "Administrator",
      })
      .then((resp) => {
        setChatId(resp.data.Data);
      })
      .catch((error) => {
        console.error("Error fetching chat ID:", error);
      });
  }, [webapiUrl, UserName]);

  // useEffect(() => {
  //   // Get the current URL
  //   const url = new URL(window.location.href);
  //   // Extract webapiUrl parameter from the URL
  //   const webapiUrlParam = url.searchParams.get('webapiUrl');
  //   // Extract username parameter from the URL
  //   const usernameParam = url.searchParams.get('username');

  //   setWebapiUrl(webapiUrlParam);
  //   setUsername(usernameParam);
  // }, []);

  return (
    <>
      <div className="chatbot-content">
        <div className="sidebar">
          <div className="chats">
            <button className="btn btn-primary" onClick={() => newChat()}>
              <i className="fa fa-plus-circle"></i> New Chat
            </button>
          </div>

          <div className="previous-chat">
            <h1>History</h1>
            <ul>
              {addChat.length > 0 &&
                addChat.map((chats, i) => {
                  const content = chats?.chat?.[0]?.content;

                  return (
                    <li
                      key={i}
                      onClick={() => {
                        updateChat(chats.chat, chats.id);
                      }}
                    >
                      <a>{content}</a>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="profile">
            <button className="tab" data-call="upgrade">
              Upgrade to Premium
            </button>
            <button className="tab">mail@sphinxworldbiz.com</button>
          </div>
        </div>

        <div className="chatbox">
          <div className="header">
            <div className="header-logo">
              <img src="img/logo.png" alt="logo" /> Pilot{" "}
              <span className="highlight-text">Beta</span>
            </div>
          </div>
          <div className="chatbox-body">
            <div ref={chatContainerRef} className="chat-content">
              {mainResult.length > 0 && (
                <div className="status">
                  <h5>{formattedDate}</h5>
                </div>
              )}
              {console.log(mainResult, "mainResul    tmainResult")}
              {mainResult.length > 0 &&
                mainResult.map((item, i) =>
                  !Array.isArray(item.content) ? (
                    <div
                      className={`chat ${
                        item.type === "user" ? "right" : "left"
                      }`}
                      key={i}
                    >
                      {item.content != "Suggestions" && (
                        <span className="callout">
                          {item.content}
                          {item.report_link}
                        </span>
                      )}

                      {item.type === "response" &&
                        !item.suggest &&
                        item.content !== "How may I help You?" &&
                        item.content !==
                          "You are not authorized to access this data" &&
                        item.content !== "No Data Found" &&
                        item.content !== "Unable to generate answer" &&
                        item.content !== "Try Another Query" &&
                        item.content !==
                          "I am having trouble understanding your query, Please refrain your query again. Thanks!!!" &&
                        !greetWelcome.includes(item.content.toLowerCase()) &&
                        !greetBye.includes(item.content.toLowerCase()) && (
                          <div className="confirmation-box">
                            <h1>Do you want to ask above question</h1>
                            <div className="d-flex flex-row justify-content-center mt-3">
                              <button
                                onClick={() => {
                                  Search(item.content, i);
                                  setConfirmationVisible(false);
                                }}
                                className="btn btn-success btn-sm"
                              >
                                Yes
                              </button>

                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                  handleConfirmBox(item.content, i);
                                }}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        )}
                      {item.type === "response" && item.suggest ? (
                        <>
                          {Array.isArray(item.suggest) && (
                            <div className="chat left" key={i}>
                              <span className="callout">
                                <h1 className="callout-heading">
                                  As per above query asked no result found, But
                                  I have some suggestions for you :-{" "}
                                </h1>
                                <ul className="callout-suggestion">
                                  {item.suggest.map((ques, i) => (
                                    <li key={i}>
                                      <a
                                        href="#;"
                                        onClick={() => suggestionSearch(ques)}
                                      >
                                        {ques}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => notInterested()}
                                >
                                  <i className="fa fa-ban"></i> Not Interested
                                </button>
                              </span>
                            </div>
                          )}

                          {/* <div className="confirmation-box">
                        <h1>Do you want to ask above question</h1>
                        <div className="d-flex flex-row justify-content-center mt-3">
                          <button onClick={() => {
                            Search(item.content, i);
                            setConfirmationVisible(false);
                          }} className="btn btn-success btn-sm">Yes</button>

                          <button className="btn btn-danger btn-sm" onClick={() => { handleConfirmBox(item.content, i) }}>No</button>
                        </div>

                      </div> */}
                        </>
                      ) : item.type === "response" &&
                        item.content !== "No Data Found" &&
                        item.content !== "Try Another Query" &&
                        item.content !== "Unable to generate answer" &&
                        item.content !==
                          "I am having trouble understanding your query, Please refrain your query again. Thanks!!!" &&
                        greetBye.includes(item.content.toLowerCase()) ? (
                        <span className="callout">Buddy!</span>
                      ) : (
                        item.type === "response" &&
                        item.content !== "How may I help You?" &&
                        item.content !== "Try Another Query" &&
                        item.content !==
                          "I am having trouble understanding your query, Please refrain your query again. Thanks!!!" && (
                          <span className="callout">How may I help you?</span>
                        )
                      )}

                      <span className="chat-info">
                        <span className="time">{item.time}</span>
                      </span>
                      <span className="sender-pic">
                        <img
                          src={
                            item.type === "user"
                              ? "img/user1.jpg"
                              : "img/company.png"
                          }
                          alt={item.type}
                        />

                        {item.type === "response" &&
                          i == recentUserIndex &&
                          circular === "visible" && (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                        {item.type === "user" &&
                          i == recentUserIndex &&
                          circular === "visible" && (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                      </span>
                    </div>
                  ) : (
                    (console.log(item.link, "tabletabletable222"),
                    Array.isArray(item.content) &&
                      item.content.length > 0 &&
                      item.type === "response" && (
                        <div className="chat left align-items-end">
                          <div className="table-responsive">
                            <div className="table-overflow">
                              <table className="table">
                                <thead>
                                  <tr>
                                    {Object.keys(item.content[0]).map(
                                      (header, index) => (
                                        <th key={index}>{header}</th>
                                      )
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {item.content.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      {Object.values(row).map(
                                        (value, colIndex) => (
                                          <td key={colIndex}>{value}</td>
                                        )
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="table-footer">
                              {/* <nav className="table-pagination">
                          <ul className="pagination">
                            <li className="page-item">
                              <button className="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&lt;</span>
                                <span className="sr-only">Previous</span>
                              </button>
                            </li>
                            <li className="page-item"><button className="page-link active" href="#">1</button></li>
                            <li className="page-item"><button className="page-link" href="#">2</button></li>
                            <li className="page-item"><button className="page-link" href="#">3</button></li>
                            <li className="page-item">
                              <button className="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&gt;</span>
                                <span className="sr-only">Next</span>
                              </button>
                            </li>
                          </ul>
                        </nav> */}
                              <div className="btn-wrapper">
                                {i == recentUserIndex + 1 && (
                                  <button
                                    className="round-btn"
                                    data-toggle="tooltip"
                                    title="Download"
                                    onClick={() => {
                                      handleDownload();
                                    }}
                                  >
                                    <i className="fa fa-download"></i>
                                  </button>
                                )}
                                {i == recentUserIndex + 1 && (
                                  <button
                                    className="round-btn"
                                    data-toggle="tooltip"
                                    title="Chart"
                                    onClick={() => {
                                      setToggleChart((prevCheck) => !prevCheck);
                                    }}
                                  >
                                    <i className="fa fa-bar-chart"></i>
                                  </button>
                                )}

                                <button
                                  className="round-btn"
                                  data-toggle="tooltip"
                                  title="Unlike"
                                  onClick={() => {
                                    if (feedbackStatus[item.id] !== "like") {
                                      feedBack("unlike", item.id);
                                    }
                                  }}
                                  style={{
                                    backgroundColor:
                                      feedbackStatus[item.id] === "unlike"
                                        ? "#11a0f8"
                                        : "none",
                                  }}
                                >
                                  <i className="fa fa-thumbs-down"></i>
                                </button>

                                <button
                                  className="round-btn"
                                  data-toggle="tooltip"
                                  title="Like"
                                  onClick={() => {
                                    if (feedbackStatus[item.id] !== "unlike") {
                                      feedBack("like", item.id);
                                    }
                                  }}
                                  style={{
                                    backgroundColor:
                                      feedbackStatus[item.id] === "like"
                                        ? "#11a0f8"
                                        : "none",
                                  }}
                                >
                                  <i className="fa fa-thumbs-up"></i>
                                </button>
                                {/* <button className="round-btn" data-toggle="tooltip" title="Table"><i className="fa fa-table"></i></button>
                          <button className="round-btn" data-toggle="tooltip" title="Refresh"><i className="fa fa-repeat"></i></button> */}
                              </div>

                              <span className="chat-info">
                                <span className="time">{item.time}</span>
                              </span>
                            </div>
                          </div>

                          <span className="sender-pic">
                            <img src="img/company.png" alt="user" />
                          </span>
                        </div>
                      ))
                  )
                )}

              {toggleChart === true && (
                <div className="chart mt-4">
                  <BarChart chartData={showSearchResult} />
                </div>
              )}
              {flag && reportlink ? (
                <div className="chat left">
                  <div>
                    Actual time taken to generate answer: {respTime} seconds
                  </div>
                  <div className="chat left flex-row gap-1">
                    {" "}
                    Please refer to the link for more detailed insights into this report :{" "}
                    {reportlink && (
                      <a
                        href={reportlink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {reportlink ? reportlink : "not"}
                      </a>
                    )}
                    {/* <a
                      href={reportlink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {reportlink ? reportlink : ""}
                    </a> */}
                  </div>
                </div>
              ) : (
                <div className="chat right">
                  Actual time taken to generate answer: {respTime} seconds
                </div>
              )}

              {/* {flag && (
                <div className="chat right">
                  Actual time taken to generate answer : {respTime} seconds
                </div>
              )} */}
            </div>

            <div className="typer">
              {suggestions.length > 0 && inputValue !== "" && (
                <ul className="list-group">
                  <li className="heading">
                    <h1>Suggestions</h1>
                  </li>
                  {suggestions.map((word, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        handleSuggestionClick(word);
                      }}
                    >
                      {word}
                    </li>
                  ))}
                </ul>
              )}
              <textarea
                className="form-control"
                placeholder="Enter your prompt here"
                id="analyticsTxtbox"
                autoComplete="off"
                value={inputValue}
                onChange={(e) => {
                  AutoSuggest(e.target.value);
                  handleInputChange(e);
                  // AutoFill(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    queryFinder(e.target.value);
                    e.target.blur();
                  }
                }}
              ></textarea>

              <div className="btn-send">
                {/* <button>
                <i className="fa fa-microphone"></i>
              </button> */}
                <button className="circle-button success-bg">
                  <i
                    className="fa fa-paper-plane"
                    onClick={() => queryFinder(inputValue)}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {circular === "visible" && (
        <div class="loader-bg">
          <div class="loader-box">
            <div class="loader"></div>
            <img src="../img/company.png" alt="logo" />
            <div class="loader-txt">Loading</div>
            <div class="timer-txt">
              {" "}
              <Timer resetTimer={circular} />{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Homepage;
